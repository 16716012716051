<div class="flex flex-col gap-6" [ngClass]="{'!-mx-6': isDialog}">
  <ng-container *ngFor="let section of sections; trackBy: trackBySectionId">
    <div *ngIf="section.questions.length > 0" class="flex flex-col gap-4" [attr.data-testid]="'section-' + section.id">
      <p class="font-medium px-4">{{ section.title | titlecase }}</p>
      <div>
        <div *ngFor="let question of section.questions; trackBy: trackByQuestionTitle">
          <pxc-form-display-question
            *ngIf="question.value"
            [label]="question.title"
            value="{{ question.value | normalize }} {{ question.unit }}"
            [indicatorLevel]="alerts[question.id]?.[0]?.level"
          ></pxc-form-display-question>
        </div>
      </div>
    </div>
  </ng-container>
</div>
