import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IndicatorLevel } from '@pixacare/pxc-ts-core';
import { TileComponent } from '@shared/components/tile/tile.component';
import { NormalizePipe } from '@shared/pipes/normalize.pipe';
import { AlertIndicatorComponent } from '../alert-indicator/alert-indicator.component';

@Component({
  selector: 'pxc-form-display-question',
  standalone: true,
  imports: [
    CommonModule,
    TileComponent,
    NormalizePipe,
    AlertIndicatorComponent,
  ],
  templateUrl: './form-display-question.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDisplayQuestionComponent {

  @Input() label: string;
  @Input() value: string;
  @Input() indicatorLevel: IndicatorLevel;

  IndicatorLevel = IndicatorLevel;

}
