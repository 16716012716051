import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { LottieModule } from 'ngx-lottie';
import { LottieLabelComponent } from './lottie-label/lottie-label.component';
import { ServerUnavailableComponent } from './server-unavailable/server-unavailable.component';
import {
  InputValidationErrorDisplayComponent,
} from './input-validation-error-display/input-validation-error-display.component';
import {
  MedicalSpecialtyInputComponent,
} from './medical-specialty-input/medical-specialty-input.component';
import { ScrollTrackerDirective } from './directives/scroll-tracker.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePreviewComponent } from './date-preview/date-preview.component';
import { OrderByPipe } from 'src/app/shared/pipes/order-by.pipe';
import { ReversePipe } from 'src/app/modules/shared/pipes/reverse.pipe';
import { CardDetailsComponent } from './card-details/card-details.component';
import { MenuActionsComponent } from './menu-actions/menu-actions.component';
import { ShowIfUserAuthorizedDirective } from './directives/show-if-user-authorized.directive';
import { ZoomContainerComponent } from './zoom-container/zoom-container.component';
import { ShowIfClientEnabledDirective } from './directives/show-if-client-enabled.directive';
import { ToggleComponent } from './toggle/toggle.component';
import { TitleComponent } from './title/title.component';
import { HandleComponent } from './handle/handle.component';
import { CardButtonComponent } from './card-button/card-button.component';
import { ChoicePickerComponent } from './choice-picker/choice-picker.component';
import { LoaderComponent } from './loader/loader.component';
import { PlatformModule } from '@angular/cdk/platform';
import { ShowIfSafariDirective } from './directives/show-if-safari.directive';
import { PdfPreviewerComponent } from './pdf-previewer/pdf-previewer.component';
import { CarouselNavigationComponent } from './carousel-navigation/carousel-navigation.component';
import { SaturatedStorageComponent } from './saturated-storage/saturated-storage.component';
import { ShowIfStorageSpaceAvailableDirective } from './directives/show-if-storage-space-available.directive';
import { JoinPipe } from 'src/app/shared/pipes/join.pipe';
import {
  TuiButtonModule,
  TuiHostedDropdownModule,
  TuiDropdownModule,
  TuiSvgModule,
  TuiTooltipModule,
  TuiAlertModule,
  TuiGroupModule,
  TuiLoaderModule,
  TuiTextfieldControllerModule,
  TuiDataListModule,
  TuiScrollbarModule,
  TuiModeModule,
  TuiHintModule,
  TuiLinkModule,
} from '@taiga-ui/core';
import { BackNavigationButtonComponent } from './back-navigation-button/back-navigation-button.component';
import { BackButtonDirective } from './directives/back-button.directive';
import { TuiActiveZoneModule, TuiLetModule } from '@taiga-ui/cdk';
import {
  TuiIslandModule,
  TuiBadgeModule,
  TuiToggleModule,
  TuiElasticContainerModule,
  TuiDataListWrapperModule,
  TuiMultiSelectModule,
  TuiComboBoxModule,
  TuiFilterByInputPipeModule,
  TuiStringifyContentPipeModule,
  TuiMarkerIconModule,
}
  from '@taiga-ui/kit';
import { ActionButtonsComponent } from './action-buttons/action-buttons.component';
import { LastUpdateBadgeComponent } from './last-update-badge/last-update-badge.component';
import { CloseConfirmableDialogComponent } from './close-confirmable-dialog/close-confirmable-dialog.component';
import { LocalDatePipe } from 'src/app/shared/pipes/local-date.pipe';
import { CreateButtonComponent } from './create-button/create-button.component';
import { PluralizePipe } from 'src/app/shared/pipes/pluralize.pipe';
import { TuiTableModule } from '@taiga-ui/addon-table';
import { DropdownButtonComponent } from './dropdown-button/dropdown-button.component';
import { UserAdminStateBadgeComponent } from './user-state-badge/user-admin-state-badge.component';
import { UserCardComponent } from 'src/app/shared/components/user-card/user-card.component';
import { SpinnerComponent } from './spinner/spinner.component';

@NgModule({
  declarations: [
    LottieLabelComponent,
    ServerUnavailableComponent,
    InputValidationErrorDisplayComponent,
    MedicalSpecialtyInputComponent,
    ScrollTrackerDirective,
    ShowIfUserAuthorizedDirective,
    ShowIfClientEnabledDirective,
    ShowIfStorageSpaceAvailableDirective,
    DatePreviewComponent,
    OrderByPipe,
    ReversePipe,
    JoinPipe,
    PluralizePipe,
    CardDetailsComponent,
    LoaderComponent,
    MenuActionsComponent,
    ToggleComponent,
    ZoomContainerComponent,
    TitleComponent,
    HandleComponent,
    CardButtonComponent,
    ChoicePickerComponent,
    PdfPreviewerComponent,
    ShowIfSafariDirective,
    CarouselNavigationComponent,
    SaturatedStorageComponent,
    BackButtonDirective,
    BackNavigationButtonComponent,
    ActionButtonsComponent,
    LastUpdateBadgeComponent,
    CloseConfirmableDialogComponent,
    LocalDatePipe,
    CreateButtonComponent,
    DropdownButtonComponent,
  ],
  imports: [
    CommonModule,
    LottieModule,
    FormsModule,
    ReactiveFormsModule,
    PlatformModule,
    TuiToggleModule,
    TuiAlertModule,
    TuiSvgModule,
    TuiIslandModule,
    TuiBadgeModule,
    TuiTooltipModule,
    TuiLetModule,
    TuiButtonModule,
    TuiHostedDropdownModule,
    TuiDropdownModule,
    TuiGroupModule,
    TuiAlertModule,
    TuiSvgModule,
    TuiDataListModule,
    TuiScrollbarModule,
    TuiLoaderModule,
    TuiElasticContainerModule,
    TuiDataListWrapperModule,
    TuiMultiSelectModule,
    TuiTextfieldControllerModule,
    TuiModeModule,
    TuiComboBoxModule,
    TuiFilterByInputPipeModule,
    TuiStringifyContentPipeModule,
    TuiTableModule,
    TuiHintModule,
    TuiMarkerIconModule,
    UserCardComponent,
    TuiActiveZoneModule,
    UserAdminStateBadgeComponent,
    SpinnerComponent,
    TuiLinkModule,
  ],
  exports: [
    InputValidationErrorDisplayComponent,
    MedicalSpecialtyInputComponent,
    ScrollTrackerDirective,
    ShowIfUserAuthorizedDirective,
    ShowIfClientEnabledDirective,
    ShowIfStorageSpaceAvailableDirective,
    DatePreviewComponent,
    OrderByPipe,
    ReversePipe,
    PluralizePipe,
    CardDetailsComponent,
    LoaderComponent,
    MenuActionsComponent,
    ToggleComponent,
    ZoomContainerComponent,
    TitleComponent,
    HandleComponent,
    CardButtonComponent,
    ChoicePickerComponent,
    PdfPreviewerComponent,
    ShowIfSafariDirective,
    CarouselNavigationComponent,
    SaturatedStorageComponent,
    BackButtonDirective,
    LottieLabelComponent,
    BackNavigationButtonComponent,
    InputValidationErrorDisplayComponent,
    ActionButtonsComponent,
    LastUpdateBadgeComponent,
    CloseConfirmableDialogComponent,
    LocalDatePipe,
    CreateButtonComponent,
    UserCardComponent,
    DropdownButtonComponent,
    UserAdminStateBadgeComponent,
  ],
  providers: [
    TitleCasePipe,
    UpperCasePipe,
  ],
})
export class SharedModule {}
