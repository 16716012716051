<ng-container *ngIf="special?.shares">
    <ng-container *tuiLet="{ resolvedShare: resolvedShare$ | async, error: resolvedShareError$ | async } as data">

        <ng-container *ngIf="!data.error; else errorBlock">
            <ng-container *ngIf="data.resolvedShare as resolvedShare; else loadingBlock">
    
                <ng-container [ngSwitch]="resolvedShare.objectType">
                    <pxc-sequence-preview
                        *ngSwitchCase="ObjectType.SEQUENCE"
                        class="cursor-auto"
                        [sequence]="resolvedShare.object"
                        [mobileMode]="true"
                        [patientRedirect]="false"
                        (mediaClicked)="mediaClicked($event, resolvedShare.object)"
                        (click)="$event.stopPropagation()"
                    ></pxc-sequence-preview>
                </ng-container>
    
            </ng-container>
        </ng-container>
    
    </ng-container>
    
    <ng-template #loadingBlock>
        <pxc-spinner></pxc-spinner>
    </ng-template>
    
    <ng-template #errorBlock>
        Une erreur est survenue lors de la récupération du partage
    </ng-template>
</ng-container>

<ng-container *ngIf="special?.telemonitoringId">
    <a
        tuiButton
        appearance="outline"
        iconRight="tuiIconExternalLink"
        [routerLink]="['/dashboard', 'telemonitorings', special.telemonitoringId]"
        queryParamsHandling="merge"
        class="mt-2"
    >
        Voir le télésuivi
    </a>
</ng-container>