<pxc-tile
  class="py-2 px-4"
  [ngClass]="{
    'bg-orange-50': indicatorLevel === IndicatorLevel.MODERATE,
    'bg-red-50': indicatorLevel === IndicatorLevel.SEVERE
  }"
>
  <pxc-alert-indicator
    leading
    [level]="indicatorLevel"
  ></pxc-alert-indicator>
  <p subtitle>{{label}}</p>
  <p 
    trailing 
    class="whitespace-pre-line text-right min-w-24 mr-2"
    [ngClass]="{
      'text-orange-700': indicatorLevel === IndicatorLevel.MODERATE,
      'text-red-700': indicatorLevel === IndicatorLevel.SEVERE
    }"
  >
    {{value}}
  </p>
</pxc-tile>