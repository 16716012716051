/* eslint-disable no-bitwise */
import { formatDate } from '@angular/common';
import Long from 'long';
import { combineLatest, map, Observable, of } from 'rxjs';

export const uuidv4 = (): string => (
  ((1e7).toString() + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (
    // eslint-disable-next-line no-bitwise
    (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16)),
  )
);

export const formatToFullDate = (date: Date): string =>
  formatDate(date, 'yyyy-MM-ddTHH:mm:ss', 'fr-FR');

export const getLocalISODate = (value: string): string =>
  new Date(new Date(`${value.replace('Z', '')}Z`).getTime() - new Date().getTimezoneOffset() * 60000).toISOString();

export const getLocalDate = (value: Date): Date =>
  new Date(value.getTime() - new Date().getTimezoneOffset() * 60000);

export const getShortDate = (value: Date, withYear: boolean = true): string => {
  let shortDate = `${value.getDate().toString().padStart(2, '0')}`
    + `/${(value.getMonth() + 1).toString().padStart(2, '0')}`;
  if (withYear) { shortDate += `/${value.getFullYear().toString()}`; }
  return shortDate;
};

export const getISODate = (date: Date): string =>
  date.toISOString().substring(0, date.toISOString().indexOf('T'));

export const isDateFromFuture = (date: Date): boolean => date.getTime() > new Date().getTime() || date.getTime() === 0;
export const isDateFromPast = (date: Date): boolean => date.getTime() < new Date().getTime() && date.getTime() !== 0;

export const msToTime = (ms: number): string => {
  const seconds = +(ms / 1000).toFixed(1);
  const minutes = +(ms / (1000 * 60)).toFixed(1);
  const hours = +(ms / (1000 * 60 * 60)).toFixed(1);
  const days = +(ms / (1000 * 60 * 60 * 24)).toFixed(1);

  if (seconds < 60) { return `${seconds} secondes`; }
  if (minutes < 60) { return `${minutes} minutes`; }
  if (hours < 24) { return `${hours} heures`; }
  return `${days} jours`;
};

export const getDateBounds = (dates: Date[]): any => dates.reduce((acc, date) => ({
  min: date.getTime() < acc.min.getTime() ? date : acc.min,
  max: date.getTime() > acc.max.getTime() ? date : acc.max,
}), { min: dates[0], max: dates[0] });

const convertIntToHex = (value: number): string => {
  const hex = value.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

const moduloClamp = (value, min = 50, max = 200) => {
  if (value < min) {
    return max - min + value;
  }
  if (value > max) {
    return value - max + min;
  }
  return value;
};

export const extractHexColorFromString = (value: string): string => {
  let hash = new Long(0, 0);
  const salt = 131;
  const maxSafeValue = Long.MAX_VALUE.divide(salt);

  for (let index = 0; index < value.length; index++) {
    if (hash.greaterThan(maxSafeValue)) {
      hash = hash.divide(salt);
    }
    hash = hash.shiftLeft(5).subtract(hash).add(value.codePointAt(index));
  }
  hash = hash.modulo(256 * 256 * 256);

  return `#${convertIntToHex(moduloClamp(hash.and(0xFF0000).shiftRight(16).modulo(255).toInt()))
  }${convertIntToHex(moduloClamp(hash.and(0xFF00).shiftRight(8).modulo(255).toInt()))
  }${convertIntToHex(moduloClamp(hash.and(0xFF).toInt()))}`;
};

export const match = (value: string, tags: string[]): boolean => {
  const tokens = (value ?? '').toLowerCase().split(' ');
  const chain = tags.map((data) => data?.toLowerCase())
    .join(' ')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  return tokens.every((token) => (
    chain.includes(token.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
  ));
};

export const lens = (entity: unknown, path: string): unknown => (
  path.split('.').reduce((obj, key) => (obj ? obj[key] : undefined), entity)
);

export const replaceAll = (str: string, find: string, replace: string): string => (
  str.replace(new RegExp(find, 'g'), replace));

export const sanitizeFilename = (filename: string): string => replaceAll(filename, ' ', '_');

export const downloadSource = (source: string, filename: string): void => {
  const link = document.createElement('a');
  link.href = source;
  link.download = sanitizeFilename(filename);
  link.click();
};

export const downloadBlob = (source: Blob, filename: string, extension: string): void => {
  const objectUrl = URL.createObjectURL(source);
  downloadSource(objectUrl, `${sanitizeFilename(filename)}.${extension}`);
  URL.revokeObjectURL(objectUrl);
};

export const downloadBase64AsImage = (source: string, filename: string, extension = 'png'): void => {
  fetch(source)
    .then((response) => response.blob())
    .then((blob) => {
      downloadBlob(blob, filename, extension);
    });
};

export const base64toBlob = (base64: string): Blob => {

  const byteString = atob(base64.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
};

export const select = <T>(elements: T[], predicate: (T) => boolean, count?: number ): T[] => {
  const selectedElements = [];
  elements.forEach((element) => {
    if (count && selectedElements.length >= count) {
      return;
    }
    if (predicate(element)) {
      selectedElements.push(element);
    }
  });
  return selectedElements;
};

export const splitLines = (lines: string[], maxLength = 124, maxLines = null): string[] => {
  const result = [...lines].reduce((acc, line) => {
    if (maxLines && acc.length >= maxLines) {
      return acc;
    } else if (line.length > maxLength) {
      const lastIndex = line.substring(0, maxLength).lastIndexOf(' ');
      if (lastIndex) {
        acc.push(
          line.substring(0, lastIndex),
          ...splitLines([line.substring(lastIndex + 1, line.length - 1)], maxLength, maxLines || (maxLines - 1)),
        );
      } else {
        acc.push(line);
      }
    } else {
      acc.push(line);
    }
    return acc;
  }, []);

  if (result.length < lines.length) {
    result[result.length - 1 ] += '...';
  }

  return result;
};

export const stringToBoolean = (value?: string): boolean => {
  try {
    return JSON.parse(value?.toLowerCase());
  } catch (error) {
    return undefined;
  }
};

export const isEverythingTrue = (...values: (boolean)[]): boolean => values.every((v) => v);

export const isEverythingTrue$ = (...values: (boolean | Observable<boolean>)[]): Observable<boolean> =>
  combineLatest(
    values.map((v) => v instanceof Observable ? v : of(v)),
  ).pipe(
    map((results) => isEverythingTrue(...results)),
  );


