import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, inject } from '@angular/core';
import { AvatarStyle, SenderType } from '@pixacare/pxc-ts-core';
import { AvatarStyleService } from 'src/app/services/avatar-style.service';
import { Size } from 'src/app/shared/models/enums/size.enum';

@Component({
  selector: 'pxc-avatar',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './avatar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements OnChanges {

  @Input() name: string;
  @Input() type: SenderType;
  @Input() size = Size.SMALL;
  @Input() avatarColors: AvatarStyle;

  Size = Size;
  text = '';

  private readonly avatarStyleService = inject(AvatarStyleService);

  ngOnChanges() {
    if (!this.avatarColors) {
      this.avatarColors = this.avatarStyleService.getAvatarColors(this.name);
    }
    this.text = this.name.split(' ').slice(0, 2).map((word) => word[0]).join('').toUpperCase();
  }

}
