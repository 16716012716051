const colors = require('tailwindcss/colors')

const customPink = {
  900: '#82204A'
}

const customRed = {
  400: '#F5726C',
  500: '#FF4C4D',
  600: '#E02C23',
}

const customBlue = {
  50: '#F4F9FC',
  100: '#D1EAFF',
  200: '#BBE0FF',
  300: '#8CCBFF',
  400: '#55AFF6',
  500: '#0F95FF',
  600: '#006ACC',
  700: '#09439A',
  800: '#073A74',
  900: '#0F2C43',
}

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    "./src/**/*.{html,ts}",
  ],
  theme: {
    container: {
      center: true,
      padding: {
        DEFAULT: '1rem',
        md: '2rem',
        xl: '4rem',
      }
    },
    fontWeight: {
      'light': 300,
      'normal': 400,
      'medium': 500,
      'bold': 600,
    },
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      black: '#000',
      white: '#fff',
      slate: colors.slate,
      orange: colors.orange,
      amber: colors.amber,
      emerald: colors.emerald,
      purple: colors.purple,
      pink: {
        ...colors.pink,
        ...customPink,
      },
      red: {
        ...colors.red,
        ...customRed,
      },
      blue: {
        ...customBlue,
      },
    }, 
    extend: {
      gridTemplateColumns: {
        'sidebar': "270px auto",
        'sidebar-collapsed': "64px auto",
      },
      colors: {
        'pixablue-1': customBlue[900],
        'pixablue-2': customBlue[300],
        'pixablue-3': customBlue[100],
        'pixapurple-1': customPink[900],
        'pixapurple-2': colors.purple[300],
        'pixapurple-3': colors.purple[100],
        'pixared-1': customRed[600],
        'pixared-2': customRed[400],
        'pixared-3': colors.red[100],
      },
      brightness: {
        '30': '.3',
      },
    },
  },
  plugins: [
    require("tailwindcss-animate"),
  ],
}
