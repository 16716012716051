<tui-scrollbar 
  *ngIf="(sequences$ | async) as sequences"
  class="h-[58vh]"
  scroll-tracker
  [threshold]="{bottom: 300}"
  (bottomThreshold)="loadNextSequences()"
  data-testid="scrollbar"
>

  <tui-checkbox-labeled
    *ngIf="!singleSelectMode"
    class="mb-3"
    [ngModel]="isAllSequencesSelected"
    (ngModelChange)="checkUncheckAll(sequences.data, $event)"
    data-testid="select-all-checkbox"
    contentAlign="left"
  >
    Tout sélectionner
  </tui-checkbox-labeled>

  <ng-container 
    *ngFor="let sequence of sequences?.data; trackBy: trackBySequenceId"
  >

    <ng-container *tuiLet="disabledSequenceIds.includes(sequence.sequenceInstance.id) as disabled">

      <tui-checkbox-labeled
        *ngIf="!singleSelectMode; else radioButton"
        [attr.data-testid]="'checkbox-' + sequence.sequenceInstance.id"
        class="w-full"
        ngDefaultControl
        [ngModel]="selectedSequenceIds.includes(sequence.sequenceInstance.id)"
        (ngModelChange)="handleCheckChange(sequence.sequenceInstance.id,sequences.data, $event)"
        [attr.data-disabled]="disabled"
        [disabled]="disabled"
      >
        <ng-container [ngTemplateOutlet]="sequenceCard"></ng-container>
      </tui-checkbox-labeled>

      <ng-template #radioButton>
        <tui-radio-labeled
          [item]="sequence.sequenceInstance.id"
          size="l"
          [attr.data-testid]="'radio-' + sequence.sequenceInstance.id"
          class="w-full"
          ngDefaultControl
          [ngModel]="selectedSequenceIds[0]"
          (ngModelChange)="handleRadioCheck(sequence.sequenceInstance.id)"
          [attr.data-disabled]="disabled"
          [disabled]="disabled"
        >
          <ng-container [ngTemplateOutlet]="sequenceCard"></ng-container>
        </tui-radio-labeled>
      </ng-template>
    </ng-container>

    <ng-template #sequenceCard>
      <tui-island>
        <pxc-sequence-preview 
          [sequence]="sequence"
          [compactMode]="compactMode"
          [mobileMode]="true"
          [patientRedirect]="false"
          [attr.data-testid]="'sequence-'+sequence.sequenceInstance.id"
        >
          <tui-notification 
            *ngIf="(badgeContent && badgeContent(sequence)) as badge"
            status="warning"
            icon="tuiIconAlertTriangle"
            [attr.data-testid]="'badge-'+sequence.sequenceInstance.id"
          >
            {{badge}}
          </tui-notification>
        </pxc-sequence-preview>
        <pxc-sequence-picker-linked-entities-list 
          [sequence]="sequence"
          [showSadm]="showSadms"
          [showTelemonitoring]="showTelemonitorings"
          (selectTelemonitoringSequences)="selectTelemonitoringSequences($event, sequences.data)"
          (selectSadmSequences)="selectSadmSequences($event, sequences.data)"
          stopPropagation
          [attr.data-testid]="'sequence-entities-'+sequence.sequenceInstance.id"
        ></pxc-sequence-picker-linked-entities-list>
      </tui-island>
    </ng-template>

  </ng-container>

  <div *ngIf="sequences?.data.length === 0 && !sequences?.context?.isNextPageLoading" class="pb-6" data-testid="empty-state">
    <pxc-lottie-label
      animation="sequenceEmpty"
      message="Vous n'avez aucune photo"
    ></pxc-lottie-label>
  </div>

  <div class="flex items-center justify-center py-4" data-testid="spinner">
    <pxc-spinner [isVisible]="sequences?.context?.isNextPageLoading"></pxc-spinner>
  </div>

</tui-scrollbar>