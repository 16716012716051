<div class="flex flex-col gap-3 h-full w-full">
  <ng-container *ngIf="reports?.length ; then summaryBlock else noSummaryBlock"></ng-container>
  <ng-template #summaryBlock>

    <pxc-sadm-entity-thumbnails-carousel
      [reports]="reports"
      [sadmEntityId]="sadmEntityId"
      [clientCode]="clientCode"
      [selectedIndex]="currentIndex"
      (selectedIndexChange)="setCurrentIndex($event)"
      data-testid="thumbnails-preview"
      [itemsCount]="mathMin(fullScreen ? 4 : 3, reports.length)"
      class="mx-auto max-w-full px-4"
    ></pxc-sadm-entity-thumbnails-carousel>

    <div class="flex-1 overflow-y-auto" [ngClass]="{'2xl:max-h-[37vh]': !fullScreen}">
      <pxc-form-display
        [sections]="reports[currentIndex].sections"
        data-testid="form-answers"
      ></pxc-form-display>
    </div>
        
    <div
      class="flex gap-3 justify-end px-4"
    >
      <ng-content></ng-content>
      <button
        *ngIf="!fullScreen"
        tuiButton
        appearance="secondary"
        iconRight="tuiIconExternalLink"
        (click)="openFullscreen()"
        size="s"
        data-testid="fullscreen-form"
      >
        Ouvrir
      </button>
    </div>

  </ng-template>
  <ng-template #noSummaryBlock>
    <div data-testid="no-summary" class="px-4">
      <h6 class="max-w-prose text-center mx-auto">Il n'y a aucun rapport d'analyse disponible.</h6>
    </div>
  </ng-template>
</div>
